export const organizeMenu = (menuData) => {
  const menuMap = {};

  menuData.forEach((menuItem) => {
    if (menuItem.type === "collapse") {
      menuMap[menuItem.key] = { ...menuItem, subMenu: [] };
    }
  });

  menuData.forEach((menuItem) => {
    if (menuItem.parentkey) {
      if (menuMap[menuItem.parentkey]) {
        menuMap[menuItem.parentkey].subMenu.push(menuItem);
      }
    } else if (menuItem.type === "item" || menuItem.type === "collapse") {
      if (!menuMap[menuItem.key]) {
        menuMap[menuItem.key] = { ...menuItem, subMenu: [] };
      }
    }
  });

  const addSubMenuToCollapse = (menu) => {
    menu.subMenu.forEach((subItem) => {
      if (!subItem.subMenu) {
        subItem.subMenu = [];
      }

      if (subItem.type === "collapse" && menuMap[subItem.key]) {
        subItem.subMenu = menuMap[subItem.key].subMenu;
        addSubMenuToCollapse(subItem);
      }
    });
  };

  Object.values(menuMap).forEach((menu) => addSubMenuToCollapse(menu));

  const sortedMenu = Object.values(menuMap)
    .filter((item) => !item.parentkey)
    .sort((a, b) => a.displayorder - b.displayorder);

  sortedMenu.forEach((menu) => {
    if (menu.subMenu.length > 0) {
      menu.subMenu.sort((a, b) => a.displayorder - b.displayorder);
    }
  });

  return sortedMenu;
};

import queryString from "query-string";
import ApiService from "services/ApiService";

// Quotation

export async function apiGetMenuList(data) {
  return ApiService.fetchData({
    url: `Menu/List?${queryString.stringify(data, {
      skipNull: true,
    })}&pageSize=1000&&orderby=0&ordercolumn=displayorder`,
    method: "get",
  });
}

export async function apiGetMenu(data) {
  return ApiService.fetchData({
    url: `Menu/Get?id=${data}`,
    method: "get",
  });
}

export async function apiGetMyMenus() {
  return ApiService.fetchData({
    url: `Menu/GetMyMenus`,
    method: "get",
  });
}

export async function apiPostMenu(data) {
  return ApiService.fetchData({
    url: `Menu/Upsert`,
    method: "post",
    data,
  });
}

export async function apiDeleteMenu(data) {
  return ApiService.fetchData({
    url: `Menu/Delete?id=${data}`,
    method: "delete",
  });
}

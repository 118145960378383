import queryString from "query-string";
import ApiService from "../ApiService";

export async function apiGetBatchProcessList(data) {
  return ApiService.fetchData({
    url: `BatchProcess/List?${queryString.stringify(data, { skipNull: true })}`,
    method: "get",
  });
}

export async function apiGetBatchProcess(data) {
  return ApiService.fetchData({
    url: `BatchProcess/Get?id=${data}`,
    method: "get",
  });
}

export async function apiRetryBatchProcess(data) {
  return ApiService.fetchData({
    url: `BatchProcess/SendQueue?id=${data}`,
    method: "get",
  });
}

export async function apiGetFeatureList(data) {
  return ApiService.fetchData({
    url: `Feature/List?${queryString.stringify(data, { skipNull: true })}`,
    method: "get",
  });
}

export async function apiGetFeature(data) {
  return ApiService.fetchData({
    url: `Feature/Get?id=${data}`,
    method: "get",
  });
}

export async function apiPostFeature(data) {
  return ApiService.fetchData({
    url: `Feature/Upsert`,
    method: "post",
    data,
  });
}

export async function apiDeleteFeature(data) {
  return ApiService.fetchData({
    url: `Feature/Delete?id=${data}`,
    method: "delete",
  });
}

export async function apiGetGeneralMapList(data) {
  return ApiService.fetchData({
    url: `GeneralMap/List?${queryString.stringify(data, { skipNull: true })}`,
    method: "get",
  });
}

export async function apiGetGeneralMap(data) {
  return ApiService.fetchData({
    url: `GeneralMap/Get?id=${data}`,
    method: "get",
  });
}

export async function apiPostGeneralMap(data) {
  return ApiService.fetchData({
    url: `GeneralMap/Upsert`,
    method: "post",
    data,
  });
}

export async function apiDeleteGeneralMap(data) {
  return ApiService.fetchData({
    url: `GeneralMap/Delete?id=${data}`,
    method: "delete",
  });
}
